export default [
  {
    name: 'Barbie Seifert',
    profesionalImg: require('./images/team/BarbieSeifert.jpg'),
    funImg: require('./images/team/BarbieSeifert.jpg'),
    linkedIn: 'https://www.linkedin.com/in/barbie-seifert-8094a22/',
    title: 'Executive Director at OneEighty',
    about: `Barbie is the Executive Director of the One Eighty Foundation, the philanthropic division of Leisure Care | One Eighty, serving as the strategic and inspirational leader to the Board of Directors, company employees, sponsors and funding agencies to achieve strategic and mission goals. With a deep understanding of operational and business development programs, she is committed to creating opportunities for better lives. 

    Outside of work, Barbie serves on the advisory committee of the Philanthropist Forum, the Corporate Giving Network, and the United Way Business and Workplace Engagement Committees.  She is an active member of the Seattle #4 Rotary, having just concluding a three year term serving on the Board of Trustees.`,
    quote: `“It is my pleasure to serve as an adviser for this passionate NEDDIE team - set on creating a revolutionary, innovative and collaborative platform that will transform the way that donors, nonprofits and business come together.”`
  },
  {
    name: 'Aimee Carpenter',
    profesionalImg: require('./images/team/AimeeCarpenter.jpg'),
    funImg: require('./images/team/AimeeCarpenter.jpg'),
    linkedIn: 'https://www.linkedin.com/in/aimeecarpenter/',
    title: 'CEO at SolTerra',
    about: `Aimee Carpenter is the CEO and owner of Solterra Solar, a Seattle based Solar Energy contractor. She leads a team of 25 people to bring solar design and installation services to homeowners and business owners across WA state. Her leadership has helped grow the company to over 2000 installed solar customers. She has been an entrepreneur in the construction industry since 2004 and is passionate about using her entrepreneurial expertise for the greater good. Her company volunteers their time with NW Harvest and the Block Project on an ongoing basis and has donated profits to Seattle Youth Hockey Assist, Block Project, NW Harvest, Equal Justice Initiative, Colors of Change and more.

    Outside of her work at Solterra, Aimee serves on the board of the Seattle Chapter of the Entrepreneurs Organization, is a big sister with Big Brothers / Big Sisters of Puget Sound, coaches incarcerated entrepreneurs in training through Defy Ventures, and is the cofounder of (F)EMPIRE, a non-profit empowering young womxn to explore entrepreneurship.`,
    quote: `“I am excited to contribute to the growth of NEDDIE in any way I can because of the incredible value NEDDIE will bring to both non profits and donors. Providing resources that allow non profits to easily promote their causes and events to a large pool of donors and volunteers will create a significant impact much bigger than any one individual is capable of achieving. There are many non profits doing amazing work and this platform will elevate their ability to do more with their limited resources. Being a part of this vision is exciting and an honor.”`
  }
];
