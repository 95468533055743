import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import plus from '../svgs/plus-circle';
import './BioModal.css';

const BioModal = (props: any) => {
  const [expanded, setExpanded] = useState(false);
  const [funImg, setFunImg] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const { person, i } = props;

  const clickOutside = ({ clientX, clientY }) => {
    // check if we've clicked outside of the expanded bio
    const element = document.elementFromPoint(clientX, clientY);
    const children: Array<Element> = [];
    if (ref.current) {
      for (let i = 0; i < ref.current.children.length; ++i) {
        children.push(ref.current.children[i]);
      }
    }

    if (
      ref.current &&
      element &&
      expanded &&
      element !== ref.current &&
      !children.includes(element)
    ) {
      contract();
    }
  };

  useEffect(() => {
    window.addEventListener('click', clickOutside);
    return () => window.removeEventListener('click', clickOutside);
  }, [expanded, funImg]);

  const handleClick = () => {
    const timer = setTimeout(() => {
      setFunImg('fun-img-animate');
    }, 10);
    if (expanded) {
      contract();
      clearTimeout(timer);
    } else {
      setExpanded(!expanded);
    }
  };

  const contract = () => {
    setFunImg('');
    setTimeout(() => {
      setExpanded(false);
    }, 100);
  };

  // define how far to move the expanded bio box based on what column it's in
  let offset = '0';
  let startPos = '0';
  if (i % 3 === 1) {
    offset = '106.25%';
    startPos = '34%';
  } else if (i % 3 === 2) {
    offset = '212.5%';
    startPos = '68%';
  }

  // non-expanded style
  const gradientStyle = {
    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 33%), url(${person.profesionalImg})`,
    backgroundSize: 'cover'
  };

  const expandedStyle = {
    backgroundImage: `none`,
    backgroundColor: `#173269`,
    animation: 'fade .2s',
    opacity: '100%',
    width: '312.5%',
    right: offset,
    zIndex: 5
  };

  const funImgStart = {
    transition: 'margin .2s',
    left: '0',
    marginLeft: startPos
  };
  const funImgEnd = {
    transition: 'margin .2s',
    left: '0',
    marginLeft: '0'
  };

  const linkedInSVG = (
    <a
      href={person.linkedIn}
      target='_blank'
      rel='noopener noreferrer'
      className='linkedin-icon'
    >
      <svg
        aria-hidden='true'
        focusable='false'
        data-prefix='fab'
        data-icon='linkedin'
        role='img'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 448 512'
      >
        <path
          fill='white'
          d='M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z'
        ></path>
      </svg>
    </a>
  );

  return (
    <div
      ref={ref}
      className={`person${expanded ? ' expanded' : ''}`}
      style={expanded ? expandedStyle : gradientStyle}
      key={i}
      data-key={i}
      onClick={(e) => handleClick()}
    >
      {expanded ? (
        <img
          src={person.funImg}
          style={funImg === 'fun-img-animate' ? funImgEnd : funImgStart}
          className={`fun-img ${funImg}`}
          alt='having fun'
        />
      ) : null}
      {expanded ? (
        <div className='expanded-content'>
          <Scrollbars style={{ height: '100%' }}>
            <section className='e-content-wrap'>
              <div style={{ fontSize: '4.5vmin', fontWeight: 600 }}>
                {person.name}
                {linkedInSVG}
              </div>
              <div
                style={{
                  fontSize: '3.5vmin',
                  fontWeight: 600,
                  marginTop: '.2vmin',
                  marginBottom: '1vmin'
                }}
              >
                {person.title}
              </div>
              <div className='person-content'>{person.about}</div>
              <div className='person-content'>{person.quote}</div>
            </section>
          </Scrollbars>
        </div>
      ) : (
        <div className='name-title'>
          <div>{person.name}</div>
          {/* {!props.board ? <div>{person.title}</div> : null} */}
          <div className='person-title-nonexp'>{person.title}</div>
        </div>
      )}
      <div className='plus-wrap'>{plus}</div>
    </div>
  );
};

export default BioModal;
