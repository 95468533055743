import React, { useState, useEffect } from 'react';
import upArrow from '../svgs/up-arrow.svg';
import upArrowBlue from '../svgs/up-arrow-blue.svg';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import * as Scroll from 'react-scroll';
import './DemoFooter.css';
import { HashLink } from 'react-router-hash-link';

let scroll = Scroll.animateScroll;

//this component contains the entire footer with request demo form NavFooter
function DemoFooter(props: any) {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleClose = () => setShow(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setModalMessage('Please enter your email to subscribe');
      setShow(true);
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setModalMessage('Please enter a valid email address');
      setShow(true);
    } else {
      setSubmitting(true);
      setShow(true);
      const reply = await axios
        .post(`/api/subscribe`, { email })
        .catch((err: any) => {
          setSubmitting(false);
          setModalMessage(
            'Error subscribing, please contact us at contact@neddie.co'
          );
          setShow(true);
        });
      if (reply && reply.status === 200) {
        setModalMessage('Subscribed!');
        setSubmitting(false);
        setShow(true);
        setEmail('');
      }
    }
  };

  const [upArrowComp, setUpArrowComp] = useState(<div></div>);
  const [topArrowText, setTopArrowText] = useState(<div></div>);

  useEffect(() => {
    if (props.light === true) {
      setUpArrowComp(
        <img className='top-arrow' alt='oops' src={upArrowBlue} />
      );
      setTopArrowText(<div className='top-light'>TOP</div>);
    } else {
      setTopArrowText(<div className='top'>TOP</div>);
      setUpArrowComp(<img className='top-arrow' alt='oops' src={upArrow} />);
    }
  }, []);

  return (
    <>
      <div className='demo-footer'>
        <div className='get-ready-for-neddie-container'>
          <div className='get-ready-for-neddie'>Get Ready for NEDDIE</div>
          <div className='subscribe-text'>Subscribe to Follow Our Updates</div>
          <form className='footer-form-container'>
            <input
              className='footer-subscribe-input'
              placeholder='Enter your email'
              name='subscribe'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <a
              href='#0'
              onClick={(event) => handleSubmit(event)}
              className='subscribe-button'
            >
              Subscribe
            </a>
          </form>
        </div>
        <div className='top-and-arrow-container'>
          {topArrowText}
          <div onClick={() => scroll.scrollToTop()}>{upArrowComp}</div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Body>
            <div style={{ textAlign: 'center' }}>
              {submitting ? (
                <Spinner animation='grow' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                modalMessage
              )}
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default DemoFooter;
