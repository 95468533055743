import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import './FormSection.css';

function FormSection(props: any) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [nameOfOrganization, setNameOfOrganization] = useState('');
  const [typeOfOrganization, setTypeOfOrganization] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleClose = () => setShow(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !emailAddress ||
      !nameOfOrganization ||
      !typeOfOrganization
    ) {
      setModalMessage('Please fill out all fields');
      setShow(true);
    } else {
      setModalMessage('Message sent!');
      setSubmitting(true);
      setShow(true);
      const reply = await axios
        .post(`/api/request`, {
          firstName,
          lastName,
          emailAddress,
          nameOfOrganization,
          typeOfOrganization
        })
        .catch((err: any) => {
          setSubmitting(false);
          setModalMessage(
            'Error sending request, please contact us at contact@neddie.co'
          );
          setShow(true);
        });

      if (reply && reply.status === 200) {
        setSubmitting(false);
        setShow(true);
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setNameOfOrganization('');
        setTypeOfOrganization('');
      }
    }
  };

  return (
    <>
      <div className='request-demo-container'>
        <div className='be-a-part-of-neddie-container'>
          <div className='be-a-part-of-neddie-title'>Be a Part of NEDDIE</div>
          <div className='be-a-part-of-neddie-text'>
            Request a demo and start growing your social impact today.
          </div>
        </div>
        <div className='request-demo-form-container'>
          <form className='request-demo-form'>
            <label className='request-form-label'>First Name</label>
            <input
              className='request-form-input'
              placeholder='Enter First Name'
              name='first-name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
            <label className='request-form-label'>Last Name</label>
            <input
              className='request-form-input'
              placeholder='Enter Last Name'
              name='last-name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            ></input>
            <label className='request-form-label'>Email Address</label>
            <input
              className='request-form-input'
              placeholder='name@example.com'
              name='email-address'
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            ></input>
            <label className='request-form-label'>Name of Organization</label>
            <input
              className='request-form-input'
              placeholder='N/A if not applicable'
              name='name-of-organization'
              value={nameOfOrganization}
              onChange={(e) => setNameOfOrganization(e.target.value)}
            ></input>
            <label className='request-form-label'>Type of Organization</label>
            <select
              className='request-form-select'
              name='name-of-organization'
              value={typeOfOrganization}
              onChange={(e) => setTypeOfOrganization(e.target.value)}
            >
              <option value='' disabled hidden>
                Select type (required)
              </option>
              <option value='501c-nonprofit'>Nonprofit(501c registered)</option>
              <option value='unregistered-nonprofit'>
                Nonprofit unregistered
              </option>
              <option value='company'>Company</option>
              <option value='individual'>Individual</option>
            </select>
            <a
              href='#0'
              onClick={(event) => handleSubmit(event)}
              className='see-how-it-works-button'
            >
              Submit
            </a>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Body>
            <div style={{ textAlign: 'center' }}>
              {submitting ? (
                <Spinner animation='grow' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                modalMessage
              )}
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default FormSection;
