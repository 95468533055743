import React, { useState, useEffect, useRef } from 'react';
import downArrow from '../svgs/down-arrow.js';
import { HashLink } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import burgerIcon from '../images/hamburger.svg';
import './NavBar.css';

const NavBar = (props: any) => {
  const [showDrop, setShowDrop] = useState(false);
  const [style, setStyle] = useState('');
  const [scrollDist, setScrollDist] = useState(window.pageYOffset);
  const [width, setWidth] = useState(window.innerWidth);
  const ref = useRef<HTMLDivElement>(null);

  const clickOutside = ({ clientX, clientY }) => {
    // check if we've clicked outside of the menu
    const element = document.elementFromPoint(clientX, clientY);
    const children: Array<Element> = [];
    if (ref.current) {
      for (let i = 0; i < ref.current.children.length; ++i) {
        children.push(ref.current.children[i]);
      }
    }

    if (
      ref.current &&
      element &&
      showDrop &&
      element !== ref.current &&
      !children.includes(element)
    ) {
      setShowDrop(false);
    }
  };

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleScroll = (e: any) => {
    setScrollDist(window.pageYOffset);
  };

  const showDropHandler = () => {
    setShowDrop(!showDrop);
  };

  const handleResize = () => {
    console.log('resize');
    setWidth(window.innerWidth);
  };

  const hamburger = () => {
    if (width > 768) {
      return (
        <>
          <span>Menu </span>
          {downArrow}
        </>
      );
    }
    return <img id='burger' src={burgerIcon}></img>;
  };

  useEffect(() => {
    window.addEventListener('click', clickOutside);
    return () => window.removeEventListener('click', clickOutside);
  }, [showDrop]);

  useEffect(() => {
    // change navbar background when we scroll down
    if (props.location.pathname !== '/' || width <= 768) {
      setStyle('scroll-style'); // only enable on main page for now
    } else {
      if (scrollDist < 50 && style !== '') {
        setStyle('');
      }
      if (scrollDist > 50 && style === '') {
        setStyle('scroll-style');
      }
    }
  }, [scrollDist, style, props.location.pathname, width]);

  useEffect(() => {
    if (props.location !== '/') setStyle('scroll-style'); // only enable on main page for now
    // allows the navbar to detect scroll event, change style onScroll
    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    // on unmount, remove scroll listener
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='nav-tab' id={style}>
      {/* <HashLink
        smooth
        to='/#neddie-home'
        className='neddie'
        scroll={(el) => scrollWithOffset(el, 100)}
      >
        NEDDIE
      </HashLink> */}
      <a href='https://www.neddie.co' className='neddie'>
        NEDDIE
      </a>
      <div className='dropdown-and-request-button'>
        <div
          className='nav-menu'
          id={`${showDrop}`}
          ref={ref}
          onClick={showDropHandler}
        >
          <div className='menu-clickable'>{hamburger()}</div>
          {showDrop ? (
            <div id='dropdown-list'>
              {props.items.map((item, i) => (
                <HashLink
                  smooth
                  className='menu-clickable'
                  to={item.to}
                  key={i}
                  scroll={(el) => scrollWithOffset(el, 140)}
                >
                  {item.text}
                </HashLink>
              ))}
              <a href='https://www.neddieblog.com' className='menu-clickable'>
                Blog
              </a>
              <HashLink
                smooth
                to='/#demo-request-form'
                className='nav-request-demo mobile-request-btn'
                scroll={(el) => scrollWithOffset(el, 100)}
              >
                Request Demo
              </HashLink>
            </div>
          ) : null}
        </div>
        {/* <HashLink
          smooth
          to='/#demo-request-form'
          className='nav-request-demo desktop-request-btn'
          scroll={(el) => scrollWithOffset(el, 100)}
        >
          Request Demo
        </HashLink> */}
        <a
          href='https://neddie.co/signup'
          className='nav-request-demo desktop-request-btn'
        >
          Get Started
        </a>
      </div>
    </div>
  );
};

export default withRouter(NavBar);
