import React, { useEffect } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

//images
import ww0Card1 from './images/Mask Group 19.svg';
import ww0Card2 from './images/Mask Group 20.svg';
import ww0Card3 from './images/Mask Group 21.svg';
import ww0Card4 from './images/Mask Group 22.svg';
import ww0Card5 from './images/Mask Group 23.svg';
import ww0Card6 from './images/Mask Group 24.svg';
import laptop1 from './images/down-scaled images/nonprofit.png';
import phones from './images/down-scaled images/mobile-screens.png';
import laptop2 from './images/down-scaled images/business.png';
import carouselimg1 from './images/down-scaled images/image0040.jpg';
import carouselimg2 from './images/down-scaled images/image0030.jpg';
import handsIn from './images/transformed-hands-in.jpg';

//svgs
import topCut from './svgs/top-cut.svg';
import bottomCut from './svgs/bottom-cut.svg';

//components
import FormSection from './components/FormSection';
import WWOCard from './components/WWOCard';
import VisionCarousel from './components/VisionCarousel';
import DemoFooter from './components/DemoFooter';
import BannerCarousel from './components/BannerCarousel';
import NavFooter from './components/NavFooter';

const carouselInfo = [
  {
    title: 'Our Mission',
    desc:
      'Empower every person to make a social impact through simple and transparent experiences',
    image: carouselimg1
  },
  {
    title: 'Our Vision',
    desc:
      'Be the most effective social impact platform for donors, nonprofits, and businesses to collaborate in support of their cause',
    image: carouselimg2
  }
];

function App(props) {
  let sampletexttext =
    'Maximize your social impact in our end-to-end social media platform. We deliver visibility into philanthropic activities that multiply exponentially and inspires others to get involved and take action.';

  useEffect(() => {
    if (!props.history.location.hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
  }, []);

  return (
    <div className='App'>
      {/*  section one (banner and title) */}
      <div id='neddie-home' className='section-one'>
        <BannerCarousel />
        <div className='banner-description'>
          <div className='banner-description-title'>
            A Social Impact Platform
          </div>
          <div className='banner-description-subtitle app-desktop-only'>
            Bringing nonprofits, donors, and businesses together for social
            impact collaboration
          </div>
          <div className='banner-description-subtitle app-mobile-only'>
            Bringing nonprofits, donors, and businesses together
          </div>
          <HashLink
            smooth
            className='see-how-it-works-button'
            to='/#nonprofits'
          >
            SEE HOW IT WORKS
          </HashLink>
        </div>
      </div>

      {/* section 2 (what we offer cards) */}
      <div className='section-two'>
        <div className='what-we-offer'>What We Offer</div>
        <div className='what-we-offer-row'>
          <WWOCard
            img={ww0Card3}
            title='Accelerate Collaboration'
            desc='Maximize your social impact with our end-to-end social media platform. We deliver visibility into philanthropic activities that multiply exponentially and inspire others to get involved and take action.'
          ></WWOCard>
          <WWOCard
            img={ww0Card4}
            title='Build Exposure'
            desc='Our innovative search engine and marketing tools provide donors quick and easy access to thousands of nonprofits, while allowing nonprofits to gain viable new donors.'
          ></WWOCard>
          <WWOCard
            img={ww0Card5}
            title='Provide Transparency'
            desc='We deliver unmatched transparency by promoting organizations that are creating a social impact. We empower these organizations to establish credibility and earn donors’ trust.'
          ></WWOCard>
        </div>
        <div className='what-we-offer-row'>
          <WWOCard
            img={ww0Card2}
            title='Harness Data'
            desc='Our  data and analysis tools help track donations, growth and reach. You can access reports to make better informed decisions for your organization.'
          ></WWOCard>
          <WWOCard
            img={ww0Card1}
            title='Solidify B2B Connections'
            desc='Multiply your connections by sharing and finding opportunities that align on shared values and initiatives. Now you can access the right contacts to start meaningful discussions and propel change.'
          ></WWOCard>
          <WWOCard
            img={ww0Card6}
            title='Fuel Growth'
            desc={`Our budgeting tools help nonprofits keep their expenses down and their revenue up. We help businesses find new customers within their own products and service's catalog.`}
          ></WWOCard>
        </div>
      </div>

      {/* beginning of section 3 (nonprofit callout) */}
      <img className='top-cut-svg' alt='oops' src={topCut}></img>
      <div id='nonprofits' className='section-three'>
        <div className='nonprofit-callout-desktop-wrapper'>
          <div className='nonprofit-callout'>
            <div className='nonprofit-callout-title'>Nonprofits</div>
            <div className='nonprofit-callout-desc'>
              Exceed your business needs with an automated functional dashboard
              that includes marketing, B2B collaboration, budgeting tools,
              campaigns, donation analytics, and much more.
            </div>
            <a href='https://neddie.co/signup' className='get-started-now'>
              Sign Up Now
            </a>
          </div>
          <div className='nonprofit-callout-img'>
            <img className='laptop1' alt='oops' src={laptop1}></img>
          </div>
        </div>

        <div className='nonprofit-callout-mobile-wrapper'>
          <div className='nonprofit-callout'>
            <div className='nonprofit-callout-title'>Nonprofits</div>
          </div>
          <div className='nonprofit-callout-img'>
            <img className='laptop1' alt='oops' src={laptop1}></img>
          </div>
          <div className='nonprofit-callout-desc'>
            Exceed your business needs with an automated functional dashboard
            that includes marketing, B2B collaboration, budgeting tools,
            campaigns, donation analytics, and much more.
          </div>
          <a href='https://neddie.co/signup' className='get-started-now'>
            Sign Up Now
          </a>
        </div>
      </div>

      {/* beginning of section 4 (Donor callout) */}
      <div id='donors' className='section-four'>
        <div className='donor-callout-desktop-wrapper'>
          <div className='donor-callout-img'>
            <img className='phones' alt='oops' src={phones}></img>
          </div>
          <div className='donor-callout'>
            <div className='donor-callout-title'>Donors</div>
            <div className='donor-callout-desc'>
              Be empowered to choose how you engage with nonprofits, share your
              social impact stories, track your donations and volunteer hours,
              and get rewarded!
            </div>
            <a
              href='https://neddie.co/signup'
              className='get-started-now'
            >
              Sign Up Now
            </a>
          </div>
        </div>

        <div className='donor-callout-mobile-wrapper'>
          <div className='donor-callout'>
            <div className='donor-callout-title'>Donors</div>
          </div>
          <div className='donor-callout-img'>
            <img className='phones' alt='oops' src={phones}></img>
          </div>
          <div className='donor-callout-desc'>
            Be empowered to choose how you engage with nonprofits, share your
            social impact stories, track your donations and volunteer hours, and
            get rewarded!
          </div>
          <a
            href='https://neddie.co/signup'
            className='get-started-now'
          >
            Sign Up Now
            </a>
        </div>
      </div>

      {/* beginning of section 5 (Buisness callout) */}
      <div id='businesses' className='section-five'>
        <div className='business-callout-desktop-wrapper'>
          <div className='business-callout'>
            <div className='business-callout-title'>Businesses</div>
            <div className='business-callout-desc'>
              Collaborate with nonprofits and donors with ease, promote your
              products and brand, while meeting your philanthropic business
              needs.
            </div>
            <a
              href='https://demo.neddie.co/#demo-request-form'
              className='get-started-now'
            >
              Preregister Now
            </a>
          </div>
          <div className='business-callout-img'>
            <img alt='oops' src={laptop2} className='laptop2'></img>
          </div>
        </div>

        <div className='business-callout-mobile-wrapper'>
          <div className='business-callout'>
            <div className='business-callout-title'>Businesses</div>
          </div>
          <div className='business-callout-img'>
            <img alt='oops' src={laptop2} className='laptop2'></img>
          </div>
          <div className='business-callout-desc'>
            Collaborate with nonprofits and donors with ease, promote your
            products and brand, while meeting your philanthropic business needs.
          </div>
          <a
            href='https://demo.neddie.co/#demo-request-form'
            className='get-started-now'
          >
            Preregister Now
          </a>
        </div>
      </div>
      <img className='bottom-cut-svg' alt='oops' src={bottomCut}></img>

      {/* beginning of mission carousel */}
      <div className='section-six'>
        <VisionCarousel info={carouselInfo}></VisionCarousel>
      </div>

      {/* beginning of partner and invest section */}
      <div id='partners-and-investors' className='section-seven'>
        <div className='partner-and-invest-wrapper'>
          <div
            className='hands-in-image'
            style={{ backgroundImage: 'url(' + handsIn + ')' }}
          ></div>
          <div className='partner-and-invest-text-wrapper'>
            <div className='partner-and-invest-title'>Partner With NEDDIE</div>
            <div className='partner-and-invest-text'>
              Promote your products and services within our large community of
              nonprofits and donors. We want to connect our community with your
              resources through B2B collaboration.
            </div>
            <div className='partner-and-invest-text'>
              Learn more about how the NEDDIE platform can help you generate
              leads and promote your business.
            </div>
            <div className='partner-and-invest-title'>Invest in NEDDIE</div>
            <div className='partner-and-invest-text'>
              With a groundbreaking platform and a large market of potential
              customers, NEDDIE is paving the future of philantropy through B2B
              and B2C collaboration.
            </div>
            <div className='partner-and-invest-text'>
              Learn more about how NEDDIE can enhance your portfolio and
              strengthen your commitment to social impact.
            </div>
            <div className='contact-us-wrapper'>
              <Link className='see-how-it-works-button' to='/contact-us'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id='demo-request-form' className='section-eight'>
        <FormSection />
      </div>

      <div className='section-nine'>
        <DemoFooter />
      </div>
      <NavFooter />
    </div>
  );
}

export default App;
