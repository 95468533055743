import React, { useState, useEffect } from 'react';
import './NavFooter.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import facebook from '../images/asset-1@3x.png';
import instagram from '../images/asset-2@3x.png';
import linkin from '../images/asset-3@3x.png';
import twitter from '../images/40px round button icon@3x.png';

import facebookDark from '../images/fb@3x.png';
import instagramDark from '../images/ins@3x.png';
import linkinDark from '../images/link@3x.png';
import twitterDark from '../images/twit@3x.png';

function NavFooter(props: any) {
  const [lightClass, setLightClass] = useState(false);

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (props.type === 'light') {
      setLightClass(true);
    }
  }, []);

  return (
    <div>
      <div className={`footer-nav-container${lightClass ? '-light' : ''}`}>
        <div className={`footer-nav${lightClass ? '-light' : ''}`}>
          <div className='nav-footer-links-wrapper'>
            <Link
              className={`footer-nav-link${lightClass ? '-light' : ''}`}
              to='/about-us'
            >
              About Us
            </Link>
            <HashLink
              smooth
              className={`footer-nav-link${lightClass ? '-light' : ''}`}
              to='/contact-us'
            >
              Contact Us
            </HashLink>
            <HashLink
              smooth
              className={`footer-nav-link${lightClass ? '-light' : ''}`}
              to='/#demo-request-form'
              scroll={(el) => scrollWithOffset(el, 100)}
            >
              Demo
            </HashLink>
            <HashLink
              className={`footer-nav-link${lightClass ? '-light' : ''}`}
              to={{ pathname: 'https://neddieblog.com' }}
              target='_blank'
            >
              Blog
            </HashLink>
            <HashLink
              smooth
              className={`footer-nav-link${lightClass ? '-light' : ''}`}
              to='/terms-and-conditions#top-of-terms-page'
              scroll={(el) => scrollWithOffset(el, 100)}
            >
              Terms
            </HashLink>
            <HashLink
              smooth
              className={`footer-nav-link${lightClass ? '-light' : ''}`}
              to='/privacy#top-of-privacy-page'
              scroll={(el) => scrollWithOffset(el, 100)}
            >
              Privacy
            </HashLink>
          </div>

          <div className={`social-media-icons`}>
            <a href='https://www.linkedin.com/company/neddie/'>
              {lightClass ? (
                <img
                  alt='linkedIn'
                  className={'social-media-icon'}
                  src={linkinDark}
                ></img>
              ) : (
                <img
                  className={'social-media-icon'}
                  alt='linkedIn'
                  src={linkin}
                ></img>
              )}
            </a>
            <a href='https://twitter.com/NEDDIE_CO'>
              {lightClass ? (
                <img
                  alt='twitter'
                  className={'social-media-icon'}
                  src={twitterDark}
                ></img>
              ) : (
                <img
                  className={'social-media-icon'}
                  alt='twitter'
                  src={twitter}
                ></img>
              )}
            </a>
            <a href='https://www.instagram.com/neddie.co/'>
              {lightClass ? (
                <img
                  alt='instagram'
                  className={'social-media-icon'}
                  src={instagramDark}
                ></img>
              ) : (
                <img
                  className={'social-media-icon'}
                  alt='instagram'
                  src={instagram}
                ></img>
              )}
            </a>
            <a href='https://www.facebook.com/neddieco'>
              {lightClass ? (
                <img
                  alt='facebook'
                  className={'social-media-icon'}
                  src={facebookDark}
                ></img>
              ) : (
                <img
                  className={'social-media-icon'}
                  alt='facebook'
                  src={facebook}
                ></img>
              )}
            </a>
          </div>
        </div>
        <div className={`copyright-statement${lightClass ? '-light' : ''}`}>
          © 2020 Neddie, all rights reserved
        </div>
      </div>
    </div>
  );
}

export default NavFooter;
