import React from 'react';
import './BannerCarousel.css';
import BannerImage from './BannerImage';
import banner1 from '../images/down-scaled images/image001.jpg';
import banner2 from '../images/down-scaled images/image002.jpg';
import banner3 from '../images/down-scaled images/image003.jpg';

function BannerCarousel(props: any) {
  return (
    <div className='banner-carousel-container'>
      <figure>
        <BannerImage img={banner1}></BannerImage>
        <BannerImage img={banner2}></BannerImage>
        <BannerImage img={banner3}></BannerImage>
        <BannerImage img={banner1}></BannerImage>
      </figure>
    </div>
  );
}

export default BannerCarousel;
