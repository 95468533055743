export default [
  {
    name: 'Laura Cancel',
    profesionalImg: require('./images/team/LauraProf.jpg'),
    funImg: require('./images/team/LauraProf.jpg'),
    linkedIn: 'https://www.linkedin.com/in/laura-cancel/',
    title: 'CEO and Founder',
    about: `Laura has been active in the nonprofit and education community for over a decade.  Being the co-founder of the nonprofit organization Puerto Rico We Care, Laura designed an effective tool that provided aid to families in hurricane stricken Puerto Rico within days. She excelled as an educator, teaching children in the areas of Math, Science and Spanish in Puerto Rico, California and Washington State. She is involved with nonprofits in Puerto Rico and Washington State helping nonprofits grow within the tech industry. Laura’s mission is to change the world by helping the nonprofit industry thrive.`,
    quote: `“I wanted to change the world at a large scale. The best way to create a big impact is by helping those that are already out there making a difference, pushing for progress, and ultimately changing the world. I created NEDDIE for those people—nonprofits and donors. How different our world would be without those that continue the hard work of making our world a better place.”`
  }
  // ,
  // {
  //   name: 'Abram Thau',
  //   profesionalImg: require('./images/team/AbramProf.jpg'),
  //   funImg: require('./images/team/AbramProf.jpg'),
  //   linkedIn: 'https://www.linkedin.com/in/abram-thau/',
  //   title: 'CTO',
  //   about: `Abram is leading the full-stack development team at NEDDIE, where he is helping to
  //     bring the software platform to life. Aside from his long history of
  //     writing code, Abram comes from a multidisciplinary background,
  //     including writing, education, robotics, and digital fabrication.`,
  //   quote: `“I am excited to work on a platform with such a defined focus on the
  //     nonprofit community and social good. The development process looks
  //     like it will have some very interesting issues to address, and I am
  //     looking forward to working with the fantastic team at NEDDIE to tackle
  //     them.”`
  // }
  ,
  {
    name: 'Stephanie Cancel',
    profesionalImg: require('./images/team/StephProf.jpg'),
    funImg: require('./images/team/StephProf.jpg'),
    linkedIn: 'https://www.linkedin.com/in/stephanie-cancel-73ba331a7/',
    title: 'Sales & Marketing PM',
    about: `Stephanie Cancel is the Marketing and Sales Project Manager at NEDDIE. With an experience of over 6 years on marketing, sales, digital marketing and social media, she is bringing the NEDDIE brand to new heights. Stephanie has been an educator for 4 years at the Spangdahlem Air Base in Germany.`,
    quote: `“One of my passions is to see how things can be improved by bringing solutions to the table. Working for NEDDIE brings me joy and so much motivation. Just knowing that what I do is helping others in a big impactful way, fulfills me. Nonprofits need a change, they deserve this, and I'm really happy to be part of that upcoming change”`
  },
  {
    name: 'Bill Coury',
    profesionalImg: require('./images/team/BillProf.jpg'),
    funImg: require('./images/team/BillProf.jpg'),
    linkedIn: 'https://www.linkedin.com/in/bill-coury-a17162174/',
    title: 'Developer',
    about: `Bill is a full-stack developer with over 20 years of excellence in project management, business development, web development, and promotions-related leadership roles. Currently a full-stack developer at NEDDIE, in hopes of making the world a better place.  Being the mastermind behind Flying Squirrel Pizza Co in Seattle, his hobbies include a passion for pizza.`,
    quote: `“What excites me about Neddie is the chance to make a difference.  As a former small business owner, I understood how hard it is to be the underdog in getting your voice heard among giants.  I hope to help give that voice to underdog nonprofits that have huge hearts and strong missions.”`
  },
  {
    name: 'Marie Lenac',
    profesionalImg: require('./images/team/MarieProf.jpg'),
    funImg: require('./images/team/MarieProf.jpg'),
    linkedIn: 'https://www.linkedin.com/in/marielenac/',
    title: 'Developer',
    about: `Marie comes from a long line of retail, sales and ops management roles and in 2019 she decided to change her career.  She attended University of Washington's Full Stack bootcamp and began her internship shortly after graduation in May, 2020.  Marie is now a full stack developer at NEDDIE, and is hoping to lift off her new career and meet some great people along the way. She loves her home state of Washington, and balances work with all the best activities the Pacific Northwest has to offer.`,
    quote: `“NEDDIE’s approach to helping people inspires me daily and I love being a part of a team that facilitates collaboration and group effort.  NEDDIE wouldn’t be what it is without the amazing team behind it and I am so grateful to be along for the ride.  I can’t wait to see how we grow and help nonprofits and donors all around the globe!  The startup energy and our internal drive to be great makes this an amazing opportunity to grow and challenge myself.”
    `
  }
];
