import React, { useState } from 'react';
import CarouselCard from './CarouselCard';

function VisionCarousel(props: any) {
  //css for text size needs adjusting
  //this handler will change the state of the carousel to render whatever JSX.Element is passed into it.
  //this is passed to the CarouselCard Component so each card can deliver it's neighbors on click.
  const clickHandler = (newRenderingComponent: JSX.Element) => {
    setCurrentCard(newRenderingComponent);
  };

  //defining doubly linked list for carousel functionality.
  class CardDoublyList {
    //option type the head (it will only ever be null if the list is empty)
    head: CardNode | null;
    constructor() {
      this.head = null;
    }

    insert(node: CardNode) {
      if (this.head === null) {
        this.head = node;
        node.prev = node;
        node.next = node;
      } else {
        //these are option typed even though this block will never execute if the head is null
        let beforeInsert: CardNode | null = this.head.prev;
        let afterInsert: CardNode | null = this.head;
        // this null check will satisfy "strict null checks" for typescript compiler even though beforeInsert will never be null at this point
        if (beforeInsert !== null) {
          beforeInsert.next = node;
          node.prev = beforeInsert;
          afterInsert.prev = node;
          node.next = afterInsert;
        }
      }
    }
  }

  class CardNode {
    title: string;
    desc: string;
    img: string;
    next: CardNode | null;
    prev: CardNode | null;
    renderingComponent: JSX.Element;
    constructor(cardInfoObject: cardInfo) {
      this.title = cardInfoObject.title;
      this.desc = cardInfoObject.desc;
      this.img = cardInfoObject.image;
      //the card takes in a reference to it's position in the doubly linked list so it can easily pass up it's neighbor on click
      this.renderingComponent = (
        <CarouselCard
          clickHandler={clickHandler}
          reference={this}
          title={this.title}
          desc={this.desc}
          image={this.img}
        ></CarouselCard>
      );
      this.next = null;
      this.prev = null;
    }
  }

  // this component is expecting an array of objects that look like cardInfo and will break if it recieves anything else.
  interface cardInfo {
    title: string;
    desc: string;
    image: string;
  }

  //initiate the data structure
  let carouselList = new CardDoublyList();

  //assemble the doubly linked list with the cardInfo passed down as an array of objects
  for (let i: number = 0; i < props.info.length; i++) {
    carouselList.insert(new CardNode(props.info[i]));
  }

  // the default state will always be the list head
  const [currentCard, setCurrentCard] = useState(
    carouselList.head?.renderingComponent
  );

  return <div className='vision-carousel'>{currentCard}</div>;
}

export default VisionCarousel;
