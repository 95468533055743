import React, { useEffect } from 'react';

function BannerImage(props: any) {
  var divStyle = {
    backgroundImage: 'url(' + props.img + ')'
  };

  return <div className='banner-image' style={divStyle}></div>;
}

export default BannerImage;
