import React from 'react';
import './Privacy.css';
import NavFooter from '../../../components/NavFooter';

function Privacy(props: any) {
  return (
    <div className='privacy-page'>
      <div id='top-of-privacy-page' className='privacy-page-content'>
        <div className='privacy-page-title-container'>
          <div className='privacy-page-title'>Privacy Policy</div>
        </div>
        <div className='privacy-page-section-title'>Introduction</div>
        <div className='privacy-page-section-text'>
          NEDDIE LLC ("Company" or "We") respect your privacy and are committed
          to protecting it through our compliance with this policy.
        </div>
        <div className='privacy-page-section-text'>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website www.neddie.co (our
          "Website") and our practices for collecting, using, maintaining,
          protecting, and disclosing that information.
        </div>

        <div className='privacy-page-section-title'>
          This policy applies to information we collect:
        </div>
        <div className='privacy-page-section-text'>On this Website.</div>
        <div className='privacy-page-section-text'>
          In email, text, and other electronic messages between you and this
          Website.
        </div>
        <div className='privacy-page-section-text'>
          When you interact with our advertising and applications on third-party
          websites and services, if those applications or advertising include
          links to this policy. Except as described above, this policy does not
          apply to information collected by:
        </div>
        <div className='privacy-page-section-text'>
          Us offline or through any other means, including on any other website
          operated by Company or any third party; or Any third party, including
          through any application or content (including advertising) that may
          link to or be accessible from the Website.
        </div>
        <div className='privacy-page-section-text'>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time (see Changes
          to Our Privacy Policy, below). Your continued use of this Website
          after we make changes is deemed to be acceptance of those changes, so
          please check the policy periodically for updates.
        </div>

        <div className='privacy-page-section-title'>
          Information We Collect About You and How We Collect It
        </div>
        <div className='privacy-page-section-text'>
          We collect several types of information from and about users of our
          Website, including information:
        </div>
        <div className='privacy-page-section-text'>
          By which you may be personally identified, such as name, email
          address, name of the organization and type of organization (registered
          501c3 non-profit, unregistered non-profit, company or individual;
          and/or,
        </div>
        <div className='privacy-page-section-text'>
          About your internet connection, the equipment you use to access our
          Website, and usage details.
        </div>

        <div className='privacy-page-section-title'>
          We collect this information:
        </div>
        <div className='privacy-page-section-text'>
          Directly from you when you provide it to us.
        </div>
        <div className='privacy-page-section-text'>
          Automatically as you navigate through the site. Information collected
          automatically may include usage details, IP addresses, and information
          collected through cookies, web beacons such as pixels, and other
          tracking technologies.
        </div>

        <div className='privacy-page-section-title'>
          Information You Provide to Us
        </div>
        <div className='privacy-page-section-text'>
          The information we collect on or through our Website may include:
        </div>
        <div className='privacy-page-section-text'>
          Information that you provide by filling in forms on our Website. This
          includes information provided at the time of registering to use our
          Website, signing up for email updates, completing forms (such as
          request demo forms), or requesting further services. We may also ask
          you for information when you report a problem with our Website.
        </div>
        <div className='privacy-page-section-text'>
          Records and copies of your correspondence (including email addresses),
          if you contact us.
        </div>
        <div className='privacy-page-section-text'>
          Your responses to surveys that we might ask you to complete for
          research purposes.
        </div>
        <div className='privacy-page-section-text'>
          Your search queries on the Website.
        </div>

        <div className='privacy-page-section-title'>
          Information We Collect Through Automatic Data Collection Technologies
        </div>
        <div className='privacy-page-section-text'>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
        </div>
        <div className='privacy-page-section-text'>
          Details of your visits to our Website, including traffic data,
          location data, logs, and other communication data and the resources
          that you access and use on the Website. Information about your
          computer and internet connection, including your IP address, operating
          system, and browser type.
        </div>
        <div className='privacy-page-section-text'>
          The information we collect automatically is only statistical data and
          does not include personal information, but we may maintain it or
          associate it with personal information we collect in other ways or
          receive from third parties. It helps us to improve our Website and to
          deliver a better and more personalized service, including by enabling
          us to:
        </div>
        <div className='privacy-page-section-text'>
          Estimate our audience size and usage patterns. Store information about
          your preferences, allowing us to customize our Website according to
          your individual interests. Speed up your searches. Recognize you when
          you return to our Website.
        </div>

        <div className='privacy-page-section-title'>
          The technologies we use for this automatic data collection may
          include:
        </div>

        <div className='privacy-page-section-text'>
          Cookies (or browser cookies). A cookie is a small file placed on the
          hard drive of your computer. You may refuse to accept browser cookies
          by activating the appropriate setting on your browser. However, if you
          select this setting you may be unable to access certain parts of our
          Website. Unless you have adjusted your browser setting so that it will
          refuse cookies, our system will issue cookies when you direct your
          browser to our Website.
        </div>
        <div className='privacy-page-section-text'>
          Flash Cookies. Certain features of our Website may use local stored
          objects (or Flash cookies) to collect and store information about your
          preferences and navigation to, from, and on our Website. Flash cookies
          are not managed by the same browser settings as are used for browser
          cookies. For information about managing your privacy and security
          settings for Flash cookies, see Choices About How We Use and Disclose
          Your Information below.
        </div>
        <div className='privacy-page-section-text'>
          Web Beacons. Pages of the Website and our email may contain small
          electronic files known as web beacons (also referred to as clear gifs,
          pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or opened an
          email and for other related website statistics (for example, recording
          the popularity of certain website content and verifying system and
          server integrity).
        </div>
        <div className='privacy-page-section-text'>
          We do not collect personal information automatically, but we may tie
          this information to personal information about you that we collect
          from other sources or you provide to us.
        </div>

        <div className='privacy-page-section-title'>
          Third-Party Use of Cookies and Other Tracking Technologies
        </div>
        <div className='privacy-page-section-text'>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or
          other tracking technologies] to collect information about you when you
          use our website. The information they collect may be associated with
          your personal information or they may collect information, including
          personal information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content.
        </div>

        <div className='privacy-page-section-title'>
          How We Use Your Information
        </div>
        <div className='privacy-page-section-text'>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </div>
        <div className='privacy-page-section-text'>
          To present our Website and its contents to you. To provide you with
          information, products, or services that you request from us. To
          fulfill any other purpose for which you provide it. To provide you
          with notices about your account, including expiration and renewal
          notices. To carry out our obligations and enforce our rights arising
          from any contracts entered into between you and us. To notify you
          about changes to our Website or any products or services we offer or
          provide though it. In any other way we may describe when you provide
          the information. For any other purpose with your consent.
        </div>

        <div className='privacy-page-section-title'>
          Disclosure of Your Information
        </div>
        <div className='privacy-page-section-text'>
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction.
        </div>
        <div className='privacy-page-section-text'>
          We may disclose personal information that we collect, or you provide
          as described in this privacy policy:
        </div>
        <div className='privacy-page-section-text'>
          To our subsidiaries and affiliates. To organizations that recruit you
          to utilize the Website. For example, if you join a NEDDIE team through
          an organization’s sourced link, that organization may receive personal
          information, as well as information on your use of the Website. To
          contractors, service providers, and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep personal information confidential and use it only for the
          purposes for which we disclose it to them. To fulfill the purpose for
          which you provide it. For example, if you give us an email address to
          use the "share by email” feature of our Website, we will transmit the
          contents of that email and your email address to the recipients. For
          any other purpose disclosed by us when you provide the information.
        </div>

        <div className='privacy-page-section-title'>With your consent</div>
        <div className='privacy-page-section-text'>
          We may also disclose your personal information:
        </div>
        <div className='privacy-page-section-text'>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request. To enforce or apply
          our Terms of Use and other agreements, including for billing and
          collection purposes. If we believe disclosure is necessary or
          appropriate to protect the rights, property, or safety of Participant
          Inc. our customers, or others. This includes exchanging information
          with other companies and organizations for the purposes of fraud
          protection.
        </div>

        <div className='privacy-page-section-title'>
          Choices About How We Use and Disclose Your Information
        </div>
        <div className='privacy-page-section-text'>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </div>
        <div className='privacy-page-section-text'>
          Tracking Technologies and Advertising. You can set your browser to
          refuse all or some browser cookies, or to alert you when cookies are
          being sent. To learn how you can manage your Flash cookie settings,
          visit the Flash player settings page on Adobe's website. If you
          disable or refuse cookies, please note that some parts of this site
          may then be inaccessible or not function properly.
        </div>
        <div className='privacy-page-section-text'>
          Civic Engagement. We will occasionally send you information about
          upcoming elections in your area, and events through our partners that
          we think you would be interested in - based on your geography, the
          organization that you joined through, and your important issues.
        </div>
        <div className='privacy-page-section-text'>
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However, these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative ("NAI") on the NAI's
          website.
        </div>

        <div className='privacy-page-section-title'>
          Accessing and Correcting Your Information
        </div>
        <div className='privacy-page-section-text'>
          You may also send us an email at contact@neddie.co to request access
          to, correct or delete any personal information that you have provided
          to us. We may not accommodate a request to change information if we
          believe the change would violate any law or legal requirement or cause
          the information to be incorrect.
        </div>
        <div className='privacy-page-section-text'>
          If you delete your User Contributions from the Website, copies of your
          User Contributions may remain viewable in cached and archived pages or
          might have been copied or stored by other Website users. Proper access
          and use of information provided on the Website, including User
          Contributions, is governed by our Terms of Use.
        </div>

        <div className='privacy-page-section-title'>Data Security</div>
        <div className='privacy-page-section-text'>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. All information you provide to us is
          stored on our secure servers behind firewalls.
        </div>

        <div className='privacy-page-section-title'>
          Changes to Our Privacy Policy
        </div>
        <div className='privacy-page-section-text'>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users'
          personal information, we will notify you by email to the email address
          specified in your account and/or through a notice on the Website home
          page. The date the privacy policy was last revised is identified at
          the top of the page. You are responsible for ensuring we have an
          up-to-date active and deliverable email address for you, and for
          periodically visiting our Website and this privacy policy to check for
          any changes.
        </div>

        <div className='privacy-page-section-title'>Contact Information</div>
        <div className='privacy-page-section-text'>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at: contact@neddie.co.
        </div>
        <div className='privacy-page-section-text'>
          To register a complaint or concern, please email us at
          contact@neddie.co.
        </div>
      </div>

      <NavFooter></NavFooter>
    </div>
  );
}

export default Privacy;
