import React from 'react';

const downSVG = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='2.77778in'
    height='2.77778in'
    viewBox='0 0 200 200'
  >
    <path
      id='Selection'
      fill='white'
      stroke='none'
      strokeWidth='1'
      d='M 23.00,75.61
   C 12.88,79.45 13.79,87.94 20.11,95.00
     20.11,95.00 41.00,116.00 41.00,116.00
     41.00,116.00 77.42,153.17 77.42,153.17
     83.77,159.80 92.84,171.89 103.00,169.61
     109.76,168.09 122.77,153.06 127.88,147.42
     127.88,147.42 176.96,98.00 176.96,98.00
     178.91,95.95 181.07,93.65 182.15,91.00
     184.64,84.83 182.21,77.79 175.00,77.11
     166.85,76.34 161.28,83.73 156.16,89.07
     156.16,89.07 118.00,128.00 118.00,128.00
     118.00,128.00 105.00,140.96 105.00,140.96
     103.33,142.49 101.49,144.46 99.00,143.80
     96.38,143.11 85.62,131.62 83.00,129.00
     83.00,129.00 46.42,91.72 46.42,91.72
     40.09,85.25 32.95,74.61 23.00,75.61 Z'
    />
  </svg>
);

export default downSVG;
