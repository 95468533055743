import React, { useState, useEffect } from 'react';
import BioModal from '../../components/BioModal';
import MobileTeamCard from '../../components/MobileTeamCard';
import teamInfo from '../../teamInfo';
import boardInfo from '../../boardOfAdvisors';
import Footer from '../../components/DemoFooter';
import NavFooter from '../../components/NavFooter';
import './AboutUs.css';

const AboutUs = (props: any) => {
  // make sure we always start out at the top of the page when we navigate here
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }, []);

  return (
    <div id='about-us-wrapper'>
      <section id='who-we-are'>
        <div id='who-text'>
          <h2>Who We Are</h2>
          <div id='who-content'>
            <p>
              We are volunteers, nonprofit founders, small business owners, &
              teachers. A passion for helping others is in our DNA.
            </p>
            <p>
              {' '}
              We've been in your shoes, we understand your business and we are
              building a social impact platform to revolutionize the way you
              work.
            </p>
          </div>
        </div>
      </section>
      <section className='meet-the-team'>
        <h2>Meet The Team</h2>
        <br />
        <div className='team-card-wrapper'>
          <div className='team-card-area'>
            {teamInfo.map((person, i) => (
              <BioModal person={person} i={i} />
            ))}
          </div>
        </div>

        <div className='team-card-wrapper-mobile'>
          <div className='team-card-area-mobile'>
            {teamInfo.map((person, i) => (
              <MobileTeamCard person={person} i={i} board={false} />
            ))}
          </div>
        </div>

        <h2 id='board-of-advs'>Board Of Advisors</h2>
        <br />
        <div id='BoA'>
          <div className='team-card-wrapper'>
            <div className='team-card-area'>
              {boardInfo.map((person, i) => (
                <BioModal person={person} i={i} board={true} />
              ))}
            </div>
          </div>

          <div className='team-card-wrapper-mobile'>
            <div className='team-card-area-mobile'>
              {boardInfo.map((person, i) => (
                <MobileTeamCard person={person} i={i} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className='about-footer'>
        <Footer light={true} />
      </div>
      <NavFooter type='light'></NavFooter>
    </div>
  );
};

export default AboutUs;
