import React, { useState, useEffect } from 'react';
import DemoFooter from '../../components/DemoFooter';
import NavFooter from '../../components/NavFooter';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import './ContactUsPage.css';

function ContactUsPage(props: any) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleClose = () => setShow(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!firstName || !lastName || !emailAddress || !message) {
      setModalMessage('Please fill out all fields');
      setShow(true);
    } else {
      setModalMessage('Message sent!');
      setSubmitting(true);
      setShow(true);
      const reply = await axios
        .post(`/api/contact`, {
          firstName,
          lastName,
          emailAddress,
          message
        })
        .catch((err: any) => {
          setSubmitting(false);
          setModalMessage(
            'Error sending message, please contact us at contact@neddie.co'
          );
          setShow(true);
        });

      if (reply && reply.status === 200) {
        setSubmitting(false);
        setShow(true);
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setMessage('');
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }, []);

  return (
    <>
      <div className='contact-us-page-wrapper' style={{ marginTop: '100px' }}>
        <div className='request-demo-container'>
          <div className='be-a-part-of-neddie-container'>
            <div className='be-a-part-of-neddie-title'>Let's Partner</div>
            <div className='be-a-part-of-neddie-text'>
              Collaborate, promote your business, and strengthen your commitment
              to social impact with NEDDIE
            </div>
          </div>
          <div className='request-demo-form-container'>
            <form className='request-demo-form'>
              <label className='request-form-label'>First Name</label>
              <input
                className='request-form-input'
                placeholder='Enter First Name'
                name='first-name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></input>
              <label className='request-form-label'>Last Name</label>
              <input
                className='request-form-input'
                placeholder='Enter Last Name'
                name='last-name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></input>
              <label className='request-form-label'>Email Address</label>
              <input
                className='request-form-input'
                placeholder='name@example.com'
                name='email-address'
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              ></input>
              <label className='request-form-label'>Message</label>
              <textarea
                className='request-form-text-field'
                name='name-of-organization'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <a
                href='#0'
                onClick={(event) => handleSubmit(event)}
                className='see-how-it-works-button'
              >
                Send Message
              </a>
            </form>
          </div>
        </div>

        <DemoFooter light={true}></DemoFooter>
        <NavFooter type='light'></NavFooter>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Body>
            <div style={{ textAlign: 'center' }}>
              {submitting ? (
                <Spinner animation='grow' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                modalMessage
              )}
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default ContactUsPage;
