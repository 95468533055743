import React, { useEffect } from 'react';
import './CarouselCard.css';
import leftArrow from '../svgs/left-arrow.svg';
import rightArrow from '../svgs/right-arrow.svg';

function CarouselCard(props: any) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.clickHandler(props.reference.next.renderingComponent);
    }, 7000);
    return () => clearTimeout(timer);
  });

  var divStyle = {
    backgroundImage: 'url(' + props.image + ')'
  };

  return (
    <div className='carousel-card'>
      <div className='carousel-card-arrows-and-info-container'>
        <img
          onClick={() =>
            props.clickHandler(props.reference.prev.renderingComponent)
          }
          className='carousel-arrow'
          alt='oopse'
          src={leftArrow}
        ></img>
        <div className='carousel-text-container'>
          <div className='carousel-title'>{props.title}</div>
          <div className='carousel-desc'>{props.desc}</div>
        </div>
        <img
          onClick={() =>
            props.clickHandler(props.reference.next.renderingComponent)
          }
          className='carousel-arrow'
          alt='oopse'
          src={rightArrow}
        ></img>
      </div>
      <div style={divStyle} className='carousel-image'></div>
    </div>
  );
}

export default CarouselCard;
