import React from 'react';
import './TermsAndConditions.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import NavFooter from '../../../components/NavFooter';

function TermsAndConditions(props: any) {
  return (
    <div id='top-of-terms-page' className='terms-and-conditions-page'>
      <div className='privacy-page-content'>
        <div className='privacy-page-title-container'>
          <div className='privacy-page-title'>Terms and Conditions</div>
        </div>
        <div className='privacy-page-section-title'>
          Acceptance of the Terms of Use
        </div>
        <div className='privacy-page-section-text'>
          These terms of use are entered into by and between you and NEDDIE LLC
          ("Company," "we," or "us"). The following terms and conditions,
          together with any documents they expressly incorporate by reference
          (collectively, "Terms of Use"), govern your access to and use of
          www.neddie.co, including any content, functionality, and services
          offered on or through www.neddie.co (the "Website"), whether as a
          guest or a registered user.
        </div>
        <div className='privacy-page-section-text'>
          Please read the Terms of Use carefully before you start to use the
          Website. By using the Website, by clicking to accept or agree to the
          Terms of Use when this option is made available to you, you accept and
          agree to be bound and abide by these Terms of Use and our Privacy
          Policy, incorporated herein by reference. If you do not want to agree
          to these Terms of Use or the Privacy Policy, you must not access or
          use the Website.
        </div>
        <div className='privacy-page-section-text'>
          This Website is offered and available to users who are 16 years of age
          or older, and reside in the United States or any of its territories or
          possessions, and citizens or lawful permanent residents of the United
          States. By using this Website, you represent and warrant that you are
          able to form a binding contract with the Company and meet all of the
          foregoing eligibility requirements. If you do not meet all of these
          requirements, you must not access or use the Website.
        </div>
        <div className='privacy-page-section-text'>
          This Website is intended for use by individuals in the United States
          only.
        </div>
        <div className='privacy-page-section-title'>Prohibited Uses</div>
        <div className='privacy-page-section-text'>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </div>
        <div className='privacy-page-section-text'>
          • In any way that violates any applicable federal, state, local, or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries). • For the purpose of exploiting, harming, or
          attempting to exploit or harm minors in any way by exposing them to
          inappropriate content, asking for personally identifiable information,
          or otherwise. • To send, knowingly receive, upload, download, use, or
          re-use any material that does not comply with the Content Standards
          set out in these Terms of Use. To transmit, or procure the sending of,
          any advertising or promotional material without our prior written
          consent, including any "junk mail," "chain letter," "spam," or any
          other similar solicitation. • To impersonate or attempt to impersonate
          the Company, a Company employee, another user, or any other person or
          entity (including, without limitation, by using email addresses
          associated with any of the foregoing). • To engage in any other
          conduct that restricts or inhibits anyone's use or enjoyment of the
          Website, or which, as determined by us, may harm the Company or users
          of the Website, or expose them to liability.
        </div>
        <div className='privacy-page-section-text'>
          Additionally, you agree not to:
        </div>
        <div className='privacy-page-section-text'>
          • Use the Website in any manner that could disable, overburden,
          damage, or impair the site or interfere with any other party's use of
          the Website, including their ability to engage in real time activities
          through the Website. • Use any robot, spider, or other automatic
          device, process, or means to access the Website for any purpose,
          including monitoring or copying any of the material on the Website. •
          Use any manual process to monitor or copy any of the material on the
          Website, or for any other purpose not expressly authorized in these
          Terms of Use, without our prior written consent. • Use any device,
          software, or routine that interferes with the proper working of the
          Website. • Introduce any viruses, Trojan horses, worms, logic bombs,
          or other material that is malicious or technologically harmful. •
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of the Website, the server on which the Website is
          stored, or any server, computer, or database connected to the Website.
          • Attack the Website via a denial-of-service attack or a distributed
          denial-of-service attack. • Otherwise attempt to interfere with the
          proper working of the Website.
        </div>

        <div className='privacy-page-section-title'>Copyright Infringement</div>
        <div className='privacy-page-section-text'>
          If you believe that any User Contributions violate your copyright,
          please email us at contact@neddie.co. It is the policy of the Company
          to terminate the user accounts of repeat infringers.
        </div>

        <div className='privacy-page-section-title'>
          Reliance on Information Posted
        </div>
        <div className='privacy-page-section-text'>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness, or usefulness of this information. Any
          reliance you place on such information is strictly at your own risk.
          We disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </div>
        <div className='privacy-page-section-text'>
          This Website includes content provided by third parties, including
          materials provided by other users, groups, and third-party licensors,
          syndicators, aggregators, and/or reporting services. All statements
          and/or opinions expressed in these materials, and all articles and
          responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </div>

        <div className='privacy-page-section-title'>Changes to the Website</div>
        <div className='privacy-page-section-text'>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </div>
        <div className='privacy-page-section-text'>
          Information About You and Your Visits to the Website: All information
          we collect on this Website is subject to our Privacy Policy. By using
          the Website, you consent to all actions taken by us with respect to
          your information in compliance with the Privacy Policy.
        </div>

        <div className='privacy-page-section-title'>
          Linking to the Website and Social Media Features
        </div>
        <div className='privacy-page-section-text'>
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but you must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part without our
          express written consent. This Website may provide certain social media
          features that enable you to:
        </div>
        <div className='privacy-page-section-text'>
          • Link from your own or certain third-party websites to certain
          content on this Website. • Send emails or other communications with
          certain content, or links to certain content, on this Website. • Cause
          limited portions of content on this Website to be displayed or appear
          to be displayed on your own or certain third-party websites.
        </div>
        <div className='privacy-page-section-text'>
          You may use these features solely as they are provided by us, and
          solely with respect to the content they are displayed with, and
          otherwise in accordance with any additional terms and conditions we
          provide with respect to such features. Subject to the foregoing, you
          must not:
        </div>
        <div className='privacy-page-section-text'>
          • Establish a link from any website that is not owned by you. Cause
          the Website or portions of it to be displayed on, or appear to be
          displayed by, any other site, for example, framing, deep linking, or
          in-line linking.• Otherwise take any action with respect to the
          materials on this Website that is inconsistent with any other
          provision of these Terms of Use.
        </div>
        <div className='privacy-page-section-text'>
          The website from which you are linking, or on which you make certain
          content accessible, must comply in all respects with the Content
          Standards set out in these Terms of Use. You agree to cooperate with
          us in causing any unauthorized framing or linking immediately to stop.
          We reserve the right to withdraw linking permission without notice. We
          may disable all or any social media features and any links at any time
          without notice in our discretion.
        </div>

        <div className='privacy-page-section-title'>Links from the Website</div>
        <div className='privacy-page-section-text'>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third-party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </div>

        <div className='privacy-page-section-title'>
          Geographic Restrictions
        </div>
        <div className='privacy-page-section-text'>
          The owner of the Website is based in the State of Washington in the
          United States. We provide this Website for use only by persons located
          in the United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If you access the Website from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </div>

        <div className='privacy-page-section-title'>
          Disclaimer of Warranties
        </div>
        <div className='privacy-page-section-text'>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data
        </div>
        <div className='privacy-page-section-text'>
          TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
          YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </div>
        <div className='privacy-page-section-text'>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE,
          ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE
          MEET YOUR NEEDS OR EXPECTATIONS.
        </div>

        <div className='privacy-page-section-title'>
          Limitation on Liability
        </div>
        <div className='privacy-page-section-text'>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
          CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
          OR OTHERWISE, EVEN IF FORESEEABLE
        </div>
        <div className='privacy-page-section-text'>
          The limitation of liability set out above does not apply to liability
          resulting from our gross negligence or willful misconduct.
        </div>
        <div className='privacy-page-section-text'>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW.
        </div>

        <div className='privacy-page-section-title'>Indemnification</div>
        <div className='privacy-page-section-text'>
          You agree that you use the Website and organize and/or attend any
          events associated with the Website at your own risk. You agree to
          defend, indemnify, and hold harmless the Company, its affiliates,
          licensors, and service providers, and its and their respective
          officers, directors, employees, contractors, agents, licensors,
          suppliers, successors, and assigns from and against any claims,
          liabilities, damages, judgments, awards, losses, costs, expenses, or
          fees (including reasonable attorneys' fees) arising out of or relating
          to your violation of these Terms of Use or your use of the Website,
          including, but not limited to, your User Contributions, attending or
          hosting any events organized using the Website, any use of the
          Website's content, services, and products other than as expressly
          authorized in these Terms of Use, or your use of any information
          obtained from the Website. The company shall not be responsible for
          any loss or damage arising from your hosting of or attendance at any
          event organized using or advertised on the Website.
        </div>
        <div className='privacy-page-section-text'>
          Your interactions with any organizations and/or individuals found on
          or through the Website, including promotion and/or attendance at any
          event, emails and other communications, payment and delivery of goods
          or services, posting of content, and any other terms, conditions,
          warranties or representations associated with such interactions, are
          solely between you and that organization(s) and/or individual(s). You
          should make whatever investigation you feel necessary or appropriate
          before proceeding with any online or offline transaction or
          communication with any of these third parties or attending any event
          sent through the Website or publicly distributed on the web or
          otherwise distributed. You agree that the Company will not be
          responsible or liable for any loss or damage of any sort incurred as
          the result of any such interactions or attendance at any such event.
        </div>

        <div className='privacy-page-section-title'>
          Governing Law and Jurisdiction
        </div>
        <div className='privacy-page-section-text'>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising from or related to them (in each case,
          including non-contractual disputes or claims), will be governed by and
          construed in accordance with the internal laws of the State of
          Washington without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Washington or any other
          jurisdiction).
        </div>
        <div className='privacy-page-section-text'>
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          Washington, in each case located in the city of Bellevue Washington
          and in King County. You waive any and all objections to the exercise
          of jurisdiction over you by such courts and to venue in such courts.
        </div>

        <div className='privacy-page-section-title'>Arbitration</div>
        <div className='privacy-page-section-text'>
          At Company's sole discretion, it may require You to submit any
          disputes arising from these Terms of Use or use of the Website,
          including disputes arising from or concerning their interpretation,
          violation, invalidity, non-performance, or termination, to final and
          binding arbitration under the Commercial Rules of Arbitration of the
          American Arbitration Association applying Washington State law.
        </div>

        <div className='privacy-page-section-title'>
          Limitation on Time to File Claims
        </div>
        <div className='privacy-page-section-text'>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </div>
        <div className='privacy-page-section-title'>
          Waiver and Severability
        </div>
        <div className='privacy-page-section-text'>
          No waiver by the Company of any term or condition set out in these
          Terms of Use will be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use will not constitute a waiver of such right or provision.
        </div>
        <div className='privacy-page-section-text'>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </div>

        <div className='privacy-page-section-title'>Entire Agreement</div>
        <div className='privacy-page-section-text'>
          The Terms of Use, and our Privacy Policy, constitute the sole and
          entire agreement between you and NEDDIE LLC. regarding the Website and
          supersede all prior and contemporaneous understandings, agreements,
          representations, and warranties, both written and oral, regarding the
          Website.
        </div>
      </div>

      <NavFooter></NavFooter>
    </div>
  );
}

export default TermsAndConditions;
