import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ContactUsPage from './pages/AboutUs/ContactUsPage';
import AboutUs from './pages/AboutUs/AboutUs';
import Privacy from './pages/AboutUs/Privacy/Privacy';
import TermsAndConditions from './pages/AboutUs/TermsAndConditions/TermsAndConditions';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from './components/NavBar';

const menuLinks = [
  { to: '/about-us', text: 'About Us' },
  { to: '/#nonprofits', text: 'Nonprofits' },
  { to: '/#donors', text: 'Donors' },
  { to: '/#businesses', text: 'Businesses' },
  { to: '/#partners-and-investors', text: 'Partners & Investors' },
  { to: '/contact-us', text: 'Contact Us' }
];

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <NavBar items={menuLinks}></NavBar>
      <Switch>
        <Route path='/contact-us' exact component={ContactUsPage} />
        <Route path='/about-us' exact component={AboutUs} />
        <Route path='/terms-and-conditions' component={TermsAndConditions} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/' component={App} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
