import React from 'react';

const plusCircle = (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    enableBackground='new 0 0 1000 1000'
  >
    <g>
      <path d='M500,10C229.8,10,10,229.8,10,500s219.8,490,490,490c270.2,0,490-219.8,490-490S770.2,10,500,10z M500,947.1C253.5,947.1,52.9,746.5,52.9,500C52.9,253.4,253.5,52.9,500,52.9c246.5,0,447.1,200.6,447.1,447.1C947.1,746.5,746.5,947.1,500,947.1z' />
      <path d='M817.3,473H517V172.8c0-11.8-9.6-21.4-21.4-21.4c-11.8,0-21.4,9.6-21.4,21.4V473H173.9c-11.8,0-21.4,9.6-21.4,21.4c0,11.8,9.6,21.4,21.4,21.4h300.2v300.2c0,11.8,9.6,21.4,21.4,21.4c11.9,0,21.4-9.6,21.4-21.4V515.9h300.2c11.8,0,21.4-9.6,21.4-21.4C838.7,482.6,829.1,473,817.3,473z' />
    </g>
  </svg>
);

export default plusCircle;
